import MissingRequiredFields from 'components/MissingRequiredFields';
import React from 'react';
import { TextWithImageSlice } from 'types.generated';

import { InnerComponent } from './InnerComponent';

export const TextWithImage = ({ slice }: { slice: TextWithImageSlice }) => {
  const { title, body, image } = slice.primary;

  if (!title.length || !body.length || !image) return <MissingRequiredFields />;

  if (slice.variation === 'bigImage' || slice.variation === 'smallImage') {
    return (
      <InnerComponent
        showMobileImageAtBottom
        items={[]}
        primary={{
          ...slice.primary,
          desktop_alignment: 'LEFT',
          mobile_alignment: 'LEFT',
          image_position: 'LEFT',
          image_size: slice.variation === 'bigImage' ? 'Big' : 'Small',
          is_column_width_flexible: false,
          show_google_reviews_badge: false,
          show_trustpilot_reviews_badge: false,
          underline: 'COLORED',
        }}
      />
    );
  }

  if (slice.variation === 'imageWithRepeatableArea') {
    return (
      <InnerComponent
        showMobileImageAtBottom
        items={slice.items}
        primary={{
          ...slice.primary,
          desktop_alignment: 'LEFT',
          mobile_alignment: 'LEFT',
          image_position: 'LEFT',
          image_size: 'Small',
          is_column_width_flexible: false,
          underline: 'COLORED',
          badge: null,
          button_link: {
            link_type: 'Any',
          },
          button_text: null,
        }}
      />
    );
  }

  return <InnerComponent primary={slice.primary} items={slice.items} />;
};
